import '../../styles/Card.scss';

function Card({ children }) {
    return (
        <h3 className='Card--header--container'>
            {children}
        </h3>
    );
}

export default Card;
