import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Nav.scss';

function Navbar({ setIsOpen, isOpen }) {
  // const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = false;

  function isSelectedLink({ isActive }) {
    const classes = ['Nav--link'];
    isActive && classes.push('Nav--link__selected');
    return classes.join(' ');
  }

  return (
    <div className={`Nav--container`}>
      <div className='Nav--options'>
        <NavLink to='/' end className={isSelectedLink}>
          home
        </NavLink>
        |
        <NavLink to='/about' end className={isSelectedLink}>
          about + contact
        </NavLink>
        |
        <NavLink to='/resume' end className={isSelectedLink}>
          resume
        </NavLink>
      </div >
    </div >
  );
}

export default Navbar;
