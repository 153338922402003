import '../styles/About.scss';

function About() {
  return (
    <div className='About--container'>
      <div className="About--description">

        <h2 className='title'>about</h2>
        <h5 className='About--socials'>email: merryutran@gmail.com</h5>
        <h5 className='About--socials'>socials:</h5>
        <h5 className='About--socials'>ig:</h5>
        <p>
          Merry Tran is an e-Learning Developer and Instructional Designer.
          She believes that learning should be easy to understand at its core,
          but easy on the eyes to be successful. Utilizing effective teaching methods,
          design principles, and functionality, Merry has created courses in the
          education, software, and automotive industry.
        </p>
        <p>
          Merry received her Master of Science in education at the California
          State University of Fullerton. While working towards her education,
          she worked on the side as a graphic designer and explored video and
          photo manipulation through many personal projects utilizing Adobe
          Premiere Pro, Illustrator, Photoshop, and other relevant software.
          In 2019, Merry moved from Los Angeles, CA to Portland, OR. There, she
          worked as a middle school technology teacher teaching software, coding,
          and other 21st century skills to sixth through eighth graders.
        </p>
        <p>
          While working through the pandemic, Merry realized she wanted to combine
          all her interests and found herself at home in e-learning. In this field,
          she was able to combine her background in curriculum with my passion for
          creation. In 2020, she started work as an e-learning developer and instructional
          designer at Evosus, an ERP software company located in Vancouver, WA. She
          created both in-house onboarding courses as well as industry-specific
          courses for Evosus’s clientele. After getting married in May 2022 and traveling
          across Southeast Asia for three months, Merry and her partner decided to move
          back to California. Merry now works at American Honda Motors in the Parts,
          Service Operation department as a Storyline Developer. There, she is
          responsible for the standardization of all of Honda and Acura’s technical
          and fix ops courses that are viewed globally by Honda and Acura technicians
          and employees. In her spare time, Merry is working on a comprehensive course
          on Articulate Storyline 360 and producing her own digital prints.
        </p>
      </div>
      <div className="About--image">
        <img src="/images/about.webp" alt="me" />
      </div>
    </div>
  );
}

export default About;
