import '../../styles/Card.scss';

function Card({ children, className }) {
    return (
        <div className={`Card--container ${className}`}>
            {children}
        </div>
    );
}

export default Card;
