import React, { useEffect, useState } from 'react';
import elearning from './data/elearning.json';
import projects from './data/other-projects.json';
import videography from './data/videography.json';
import { useLocation } from 'react-router-dom';
import Grid from '../../components/Grid';

function Grids() {
    const { pathname } = useLocation();
    const [data, setData] = useState({});

    useEffect(() => {
        let data = {};
        switch (pathname) {
            case ('/videography'):
                data = videography;
                break;
            case ('/elearning'):
                data = elearning;
                break;
            case ('/projects'):
                data = projects;
                break;
            default:
        }
        setData(data)
    }, [pathname])


    return <Grid data={data} />
}

export default Grids;
