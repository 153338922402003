import '../../styles/Card.scss';

function CardLink({ children, href }) {
    return (
        <a href={href} className={'Card--link ' + (href ? 'Card--link__hover' : 'Card--link__disabled')}>
            {children}
        </a>
    );
}

export default CardLink;
