import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './styles/globals.scss';
import './styles/typography.scss';
import App from './views/App';
import About from './views/About';
import Resume from './views/Resume';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Grids from './views/Grids';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/about' element={<About />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/elearning' element={<Grids />} />
          <Route path='/videography' element={<Grids />} />
          <Route path='/projects' element={<Grids />} />
        </Routes>
      </Layout>
    </Router>
  </React.StrictMode>
);
