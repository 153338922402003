import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Nav';
import '../styles/Layout.scss';

function Layout({ children }) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <div className='Layout--container'>
      <Navbar setIsOpen={setIsOpen} isOpen={isOpen} />
      <div className='flex-center'>{children}</div>
    </div>
  );
}
export default Layout;
