import '../styles/App.scss';
import { Link } from 'react-router-dom';

function App() {
  const title = 'MERRY TRAN';

  return (
    <section className='App--container'>
      <h2 className='App--title'>
        {title}
      </h2>
      <div className="App--grid">
        <Link to="/elearning">
          <button className='App--button'>E-Learning</button>
        </Link>
        <a href="https://www.instagram.com/merryutran/?hl=en" target='_blank'>
          <button className='App--button'>Photography</button>
        </a>
        <Link to="/videography">
          <button className='App--button'>Videography</button>
        </Link>
        <Link to="/projects">
          <button className='App--button'>Other Projects</button>
        </Link>
      </div>
    </section>
  );
}

export default App;
