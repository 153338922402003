import '../../styles/Card.scss';

function CardImage({ src, alt }) {
    return (
        <div className="Card--image--container">
            <div className="Card--image--bounds">
                <img className='Card--image' src={src} alt={alt} />
            </div>
        </div>
    );
}

export default CardImage;
