import { Card, CardContent, CardHeader, CardImage, CardLink, CardVideo } from '../../components/Card';
import { FaExternalLinkAlt } from 'react-icons/fa'
import classNames from 'classnames';
import '../../styles/Grid.scss';
import '../../styles/Card.scss';

function Grid({ data }) {
    const { title, description, content, options } = data;
    if (!title || !description || !content) return null;
    console.log(options)

    const gridClasses = classNames(
        'Grid--grid',
        {
            'Grid--grid__columns': options?.twinColumns
        }
    )

    return (
        <section className='Grid--container'>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className={gridClasses}>
                {content.map(({ uri, title, description, imageUri }) => (
                    <Card>
                        {imageUri &&
                            <CardImage src={imageUri} alt={title} />
                        }
                        <CardContent>
                            <CardHeader>
                                {title}
                            </CardHeader>
                            <p>
                                {description}
                            </p>
                            {/* {uri &&
                                <CardLink href={uri}>
                                    demo <FaExternalLinkAlt fill='white' stroke='white' size={'.8rem'} />
                                </CardLink>
                            } */}
                        </CardContent>
                    </Card>
                ))}
            </div>
        </section>
    );
}

export default Grid;
